import React from "react";
import {
  createMuiTheme,
  ThemeProvider,
  List,
  ListItem,
  ListItemText,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Typography,
  Grid,
  Divider,
  LinearProgress,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import {
  AccessTime as AccessTimeIcon,
  Assignment as AssignmentIcon,
  Score as ScoreIcon,
} from "@material-ui/icons";


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ff0000",
    },
  },
});

const mockQuestions = [
  {
    id: 1,
    title: "帮妈妈叠被子。",
    options: [
      { id: "a", text: "Please help mommy fold the blanket." },
      { id: "b", text: "Please help mommy pack the quilt." },
      { id: "c", text: "Please help mommy get the blanket." },
    ],
    correctAnswer: "a",
  },
  {
    id: 2,
    title: "秋裤",
    options: [
      { id: "a", text: "autumn pants" },
      { id: "b", text: "thermal pants" },
      { id: "c", text: "fall pants" },
    ],
    correctAnswer: "b",
  },
  {
    id: 3,
    title: "裤子提上来。",
    options: [
      { id: "a", text: "Put on your pants." },
      { id: "b", text: "Pull up your pants." },
      { id: "c", text: "Lift up your pants." },
    ],
    correctAnswer: "b",
  },
  {
    id: 4,
    title: "你想玩玩具汽车吗？",
    options: [
      { id: "a", text: "Do you want to play with your toy cars?" },
      { id: "b", text: "Do you want to play your toy cars?" },
      { id: "c", text: "Do you want to play on your toy cars?" },
    ],
    correctAnswer: "a",
  },
  {
    id: 5,
    title: "我早点下班带你去游乐场。",
    options: [
      { id: "a", text: "I'll leave work early and bring you to the playground." },
      { id: "b", text: "I'll get off work early and take you to the playground." },
      { id: "c", text: "I'll go to work early and take you to the playground." },
    ],
    correctAnswer: "b",
  },
  {
    id: 6,
    title: "我掉了一颗牙。",
    options: [
      { id: "a", text: "I lost a tooth." },
      { id: "b", text: "I lost a teeth." },
      { id: "c", text: "I lost a piece of tooth." },
    ],
    correctAnswer: "a",
  },
  {
    id: 7,
    title: "穿上睡衣。",
    options: [
      { id: "a", text: "Put on your pajamas." },
      { id: "b", text: "Put up your pajama." },
      { id: "c", text: "Wear on your pajamas." },
      { id: "d", text: "Wear up your pajama." },
    ],
    correctAnswer: "a",
  },
  {
    id: 8,
    title: "绘本",
    options: [
      { id: "a", text: "picture book" },
      { id: "b", text: "drawing book" },
      { id: "c", text: "painting book" },
      { id: "d", text: "text book" },
    ],
    correctAnswer: "a",
  },
  {
    id: 9,
    title: "该洗澡了。",
    options: [
      { id: "a", text: "It's time to wash a bash." },
      { id: "b", text: "It's time to brush a bath." },
      { id: "c", text: "It's time to take a bath." },
      { id: "d", text: "It's time to rinse a bath." },
    ],
    correctAnswer: "c",
  },
  {
    id: 10,
    title: "你想让妈妈抱着你走吗？",
    options: [
      { id: "a", text: "Do you want mommy to hug you?" },
      { id: "b", text: "Do you want mommy to pick you up?" },
      { id: "c", text: "Do you want mommy to carry you?" },
      { id: "d", text: "Do you want mommy to hold you?" },
    ],
    correctAnswer: "c",
  },
];

class EnglishStarterTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: mockQuestions,
      selectedAnswers: {},
      submitted: false,
      confirmed: false,
      score: null,
    };
  }

  handleAnswerChange = (questionId, answerId) => {
    this.setState(prevState => ({
      selectedAnswers: {
        ...prevState.selectedAnswers,
        [questionId]: answerId,
      },
    }));
  };

  handleSubmit = () => {
    const { questions, selectedAnswers } = this.state;
    const totalQuestions = questions.length;
    let score = 0;
    for (let i = 0; i < totalQuestions; i++) {
      const question = questions[i];
      const selectedAnswer = selectedAnswers[question.id];
      if (selectedAnswer === question.correctAnswer) {
        score += 10;
      }
    }
    console.log("Score:", score);
    this.setState({ submitted: true, score });
  };

  handleClose = () => {
    this.setState({ submitted: false, score: null });
  };
  handleConfirm = () => {
    this.setState({ confirmed: true,submitted:false });
  };

  render() {
    const { questions, selectedAnswers, submitted, score, confirmed } = this.state;
    const totalQuestions = questions.length;
    const totalScore = 100;
    const completedQuestions = Object.keys(selectedAnswers).length;
    const completedScore = completedQuestions * (totalScore / totalQuestions);

    return (
      <ThemeProvider theme={theme}>
        <div>
        {confirmed==false && (<div>
          <div style={{ position: "relative" }}>
            <LinearProgress
              variant="determinate"
              value={(completedQuestions / totalQuestions) * 100}
              style={{ position: "fixed", top: 0, left: 0, right: 0 }}
            />
          </div>
          
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            style={{ position: "fixed", top: 0, left: 0 }}
          >
            <Grid item>
              <Tooltip title="No time limit">
                <div>
                  <AccessTimeIcon />
                  <Typography variant="caption">No time limit</Typography>
                </div>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={`${totalQuestions} questions`}>
                <div>
                  <AssignmentIcon />
                  <Typography variant="caption">
                    {totalQuestions} questions
                  </Typography>
                </div>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={`${totalScore} scores`}>
                <div>
                  <ScoreIcon />
                  <Typography variant="caption">
                    {totalScore} scores
                  </Typography>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
          <List>
            {questions.map((question, index) => (
              <React.Fragment key={question.id}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="h6">
                        {index + 1}. {question.title}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <RadioGroup
                        aria-label={`Question ${question.id}`}
                        name={`question-${question.id}`}
                        value={selectedAnswers[question.id] || ""}
                        onChange={event =>
                          this.handleAnswerChange(
                            question.id,
                            event.target.value
                          )
                        }
                      >
                        {question.options.map(option => (
                          <FormControlLabel
                            key={option.id}
                            value={option.id}
                            control={<Radio />}
                            label={
                              <Typography>
                                {option.text.split(" ").map((word, index) => (
                                  <span
                                    key={index}
                                    className={`option-word-${index % 3}`}
                                  >
                                    {word}{" "}
                                  </span>
                                ))}
                              </Typography>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          {!submitted && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          )}
          </div>)}
          {submitted && (
            <Dialog open={open} onClose={this.handleClose}>
              <DialogTitle>Your Score</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Your score is {score} out of {totalScore}.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Close
                </Button>
                <Button onClick={this.handleConfirm} color="primary">
                  Confirm
                </Button>
              </DialogActions>
              
            </Dialog>
          )}
          {confirmed && (
                <img src="https://files.kids-abc.cn/%E5%85%AC%E4%BC%97%E5%8F%B7%E5%81%9A%E9%A2%98%E4%BB%A5%E5%90%8E%E5%9B%BE%E7%89%87.jpg" alt="placeholder" style={{ width: '100%', height: 'auto' }} />
              )}
        </div>
      </ThemeProvider>
    );
  }
}

export default EnglishStarterTest;